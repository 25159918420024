<template>
  <no-subcription v-if="!getSubscription"/>
  <div v-else class="page-add-inventory">
    <app-loading v-show="loadingData"/>
    <v-container v-if="!loadingData">
      <v-card>
        <v-card-title>
          <p>{{ $vuetify.lang.t("$vuetify.menu.sell_types_payment") }}</p>
        </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item.text"
            >
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                <v-card-text>
                  <resume-filter-component @updateFilter="loadData"/>
                </v-card-text>
                <v-expansion-panels
                  v-model="panel"
                  style="margin: 0"
                  multiple
                >
                  <v-expansion-panel style="margin: 0">
                    <v-expansion-panel-header>
                      <div>
                        <v-icon>mdi-chart-line</v-icon>
                        <span
                          style="text-transform: uppercase;font-weight: bold"
                        >
                    {{ $vuetify.lang.t("$vuetify.report.graphics") }}
                  </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col
                          class="py-0"
                          cols="12"
                          :md="localSalesByPayments.length > 0  ? 4 : 12"
                        >
                          <v-card
                            class="mx-auto"
                            tile
                          >
                            <v-list dense>
                              <v-subheader>
                                {{
                                  localSalesByPayments.length >
                                  0
                                    ? $vuetify.lang.t(
                                    "$vuetify.report.top5Payment"
                                    )
                                    : $vuetify.lang.t(
                                    "$vuetify.report.noTop5"
                                    )
                                }}
                              </v-subheader>

                              <v-subheader v-if="localSalesByPayments.length > 0">
                                <v-slider
                                  v-model="sliderModel"
                                  :label="$vuetify.lang.t('$vuetify.actions.show')"
                                  :thumb-color="'green lighten-1'"
                                  thumb-label="always"
                                  :max="max"
                                  :min="min"
                                ></v-slider>
                              </v-subheader>
                              <v-list-item-group
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in localSalesByPayments.slice(0,this.sliderModel)"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="
                                        `${$vuetify.lang.t(
                                          '$vuetify.payment.' +
                                            item.name
                                        )}`
                                      "
                                    />
                                    <v-list-item-subtitle
                                      v-text="
                                        `${parseFloat(item.totalPrice).toFixed(2) + ' ' + getCurrency}`
                                      "
                                    />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="localSalesByPayments.length > 0"
                          class="py-0"
                          cols="12"
                          md="7"
                        >
                          <highcharts :options="chartOptions"/>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel style="margin: 0">
                    <v-expansion-panel-header>
                      <div>
                        <v-icon>mdi-table-large</v-icon>
                        <span
                          style="text-transform: uppercase;font-weight: bold"
                        >
                    {{ $vuetify.lang.t("$vuetify.panel.basic") }}
                  </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <app-data-table
                        :title="
                    $vuetify.lang.t(
                      '$vuetify.titles.list',
                      [
                        $vuetify.lang.t(
                          '$vuetify.menu.vending'
                        )
                      ]
                    )
                  "
                        :headers="getTableColumns"
                        csv-filename="ProductBuys"
                        :items="localSalesByPayments"
                        :options="vBindOption"
                        :sort-by="['no_facture']"
                        :sort-desc="[false, true]"
                        multi-sort
                        :view-show-filter="false"
                        :view-edit-button="false"
                        :view-new-button="false"
                        :view-delete-button="false"
                        :is-loading="isTableLoading"
                      >
                        <template v-slot:item.name="{ item }">
                          {{ $vuetify.lang.t('$vuetify.payment.' + item.name) }}
                        </template>
                        <template v-slot:item.cantTransactions="{ item }">
                          {{ parseFloat(item.cantTransactions).toFixed(2) }}
                        </template>
                        <template v-slot:item.totalRefunds="{ item }">
                          {{ parseFloat(item.totalRefunds).toFixed(2) + ' ' + getCurrency }}
                        </template>
                        <template v-slot:item.totalPrice="{ item }">
                          {{ parseFloat(item.totalPrice).toFixed(2) + ' ' + getCurrency }}
                        </template>
                      </app-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                <v-card-text v-if="localSalesByPayments.length > 0 && firstDate && secondDate">
                  <sale-summary :products-data="getSalesProductData" :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                  <p>El(s) método(s) de pago por el que más se cotiza:</p>
                  <payment-summary :payments="localSalesByPayments"/>
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item v-for="(item, i) in localSalesByPayments.slice(0, this.sliderModel)"
                                 :key="i">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="`${$vuetify.lang.t('$vuetify.payment.' + item.name)}`"/>
                        <v-list-item-subtitle
                          v-text="`${parseFloat(item.totalPrice).toFixed(2) + ' ' + user.company.currency}`"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-card-text>
                <v-card-text v-else>
                  <p v-if=" firstDate && secondDate">En el intervalo desde
                    {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
                    hasta
                    {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
                    no se han efectuado ventas</p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import AppLoading from '../../../components/core/AppLoading'
import { Chart } from 'highcharts-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResumeFilterComponent from './ResumeFilterComponent'
import SaleSummary from './summary/SaleSummary'
import PaymentSummary from './summary/PaymentSumary'

export default {
  name: 'SalesPayment',
  components: { PaymentSummary, SaleSummary, ResumeFilterComponent, AppLoading, highcharts: Chart },
  data () {
    return {
      totalSale: 0.00,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.report.graphics'),
        icon: 'mdi-chart-bar'
      }, {
        text: this.$vuetify.lang.t('$vuetify.menu.resume'),
        icon: 'mdi-file-document'
      }],
      min: 0,
      max: 5,
      sliderModel: 5,
      loadingData: false,
      panel: [0],
      firstDate: new Date(),
      secondDate: new Date(),
      seriesData: [],
      chartOptions: {},
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: false
      },
      menu: false,
      formRule: this.$rules,
      localShops: [],
      localSalesByPayments: []
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    ...mapState('sale', ['salesByPayments', 'isTableLoading']),
    ...mapState('payment', ['paymentsConst']),
    ...mapGetters('sale', ['getSalesProductData']),
    ...mapGetters('auth', ['user']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_sale'),
          value: 'cantTransactions',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.tax.total_pay_tax'),
          value: 'totalRefunds',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.netPrice'),
          value: 'totalPrice',
          select_filter: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('sale', ['getSalesByPayment']),
    ...mapActions('payment', ['loadPaymentsConst']),
    async loadData (firstDate, secondDate, localShop) {
      if (firstDate && secondDate && localShop) {
        this.firstDate = firstDate
        this.secondDate = secondDate
        await this.getSalesByPayment({
          shops: localShop,
          dates: [this.firstDate, this.secondDate],
          online: this.$route.path.split('online').length > 1
        })
          .then(() => {
            this.localSalesByPayments =
              this.salesByPayments.sort(function (a, b) {
                if (a.totalPrice > b.totalPrice) return -1
                if (a.totalPrice < b.totalPrice) return 1
                return 0
              })
          })
        const categories = []
        const series = {
          cantTransactions: [],
          totalPrice: [],
          totalRefunds: []
        }
        this.localSalesByPayments.slice(0, this.sliderModel).forEach(v => {
          if (v.name !== undefined) {
            categories.push(
              this.$vuetify.lang.t('$vuetify.payment.' + v.name)
            )
            series.totalPrice.push(v.totalPrice)
            series.totalRefunds.push(v.totalRefunds)
          }
        })
        this.chartOptions = {
          chart: {
            type: 'column'
          },
          title: {
            text: this.$vuetify.lang.t('$vuetify.report.barGraphics')
          },
          xAxis: {
            categories: categories,
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: this.getCurrency
            }
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"><b>{point.key}</b></span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} ' +
              this.getCurrency +
              '</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            {
              name: this.$vuetify.lang.t(
                '$vuetify.variants.total_price'
              ),
              data: series.totalPrice
            },
            {
              name: this.$vuetify.lang.t('$vuetify.menu.refund'),
              data: series.totalRefunds
            }
          ],
          credits: {
            enabled: false
          }
        }
      }
    },
    rowClick ($event) {
    }
  }
}
</script>

<style scoped></style>
