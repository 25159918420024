<template>
  <v-list-item-group
    color="primary"
  >
    <v-list-item v-for="(item, i) in payments.slice(0, this.sliderModel)"
                 :key="i">
      <v-list-item-content>
        <v-list-item-title
          v-text="`${$vuetify.lang.t('$vuetify.payment.' + item.name)}`"/>
        <v-list-item-subtitle
          v-text="`${parseFloat(item.totalPrice).toFixed(2) + ' ' + currency}`"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: 'PaymentSummary',
  props: {
    payments: {
      type: Array,
      default: function () {
        return []
      }
    },
    currency: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
